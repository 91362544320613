<template>
  <div
    class="rounded-xl transition-all hover:shadow-xl cursor-pointer overflow-hidden relative flex-shrink-0 min-h-28"
    :class="{
      'bg-primary bg-opacity-10 hover:bg-opacity-5': !isClosedToApplications,
      'bg-gray-300 bg-opacity-30 hover:bg-opacity-20': isClosedToApplications,
    }"
    @click="$emit('open')"
  >
    <div class="flex flex-col p-4 h-full gap-2">
      <div class="font-semibold text-sm flex gap-2">
        <div class="flex-1">{{ $t(`procedureType.${procedure.type}`) }}</div>
        <div v-if="isOpenToApplications" class="relative flex-shrink-0">
          <fw-dot color="primary" inline class="mt-1" />
        </div>
      </div>
      <div class="flex-1 flex gap-1 items-end">
        <div class="flex-1 flex flex-col justify-end">
          <div class="font-semibold text-xl">
            <v-clamp autoresize :max-lines="2">{{ procedure.code }}</v-clamp>
          </div>
          <div class="flex gap-3 font-medium">
            <div>{{ procedure.number_of_seats || '99' }} {{ $tc('seats', procedure.number_of_seats) }}</div>
            <div>{{ procedure.duration || '999' }} {{ $tc('durationUnit', procedure.duration) }}</div>
          </div>
          <div
            v-if="procedure.scholarship_project.name[language] || procedure.scholarship_project.reference"
            class="mt-3 flex flex-col gap-1"
          >
            <div class="text-xs font-semibold">
              <v-clamp autoresize :max-lines="2">{{ procedure.scholarship_project.name[language] }}</v-clamp>
            </div>
            <div class="text-xs">
              <v-clamp autoresize :max-lines="1">{{ procedure.scholarship_project.reference }}</v-clamp>
            </div>
          </div>
          <div class="mt-2 border-t border-gray-300 pt-2 text-sm text-gray-500 font-medium">
            <div v-if="isOpenToApplications">
              <div class="inline-flex flex-shrink-0 text-primary relative gap-2">
                <div class="font-semibold">Candidaturas abertas</div>
              </div>
              <div class="text-primary text-xs font-medium opacity-80">
                Até {{ procedure.publish_end | formatDate }}
              </div>
            </div>
            <div v-else-if="isOpeningSoon">
              <div class="text-primary font-semibold">Brevemente</div>
              <div class="text-primary text-xs opacity-80 font-medium">
                A partir de {{ procedure.publish_start | formatDate }}
              </div>
            </div>
            <div v-else class="text-xs">
              Candidaturas encerradas
            </div>
          </div>
        </div>
        <div>
          <fw-icon-chevron-right class="w-6 h-6 text-gray-500 flex-shrink-0 -mr-1.5 -mb-1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardProcedure',

  props: {
    procedure: {
      type: Object,
    },
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },

    // Checks
    isOpenToApplications() {
      return (
        this.procedure.time_for_publish_end > 0 &&
        this.procedure.time_for_publish_start < 0 &&
        this.procedure.public_status == 'open'
      )
    },
    isClosedToApplications() {
      return this.procedure.time_for_publish_end < 1
    },
    isOpeningSoon() {
      return this.procedure.time_for_publish_start > 0 && this.procedure.status == 'published'
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "seats": "vaga | vagas",
      "durationUnit": "mês | meses",
      "procedureType": {
        "teachers": "Professores",
        "researchers": "Investigadores",
        "researchers-dl57": "Investigadores DL 57",
        "scholarships": "Bolsas",
        "staff": "Pessoal não docente"
      }
    },
    "en": {
      "seats": "seat | seats",
      "durationUnit": "month | months",
      "procedureType": {
        "teachers": "Teachers",
        "researchers": "Researchers",
        "researchers-dl57": "Researchers DL 57",
        "scholarships": "Scholarships",
        "staff": "Staff"
      }
    }
  }
</i18n>
