<template>
  <fw-layout mobile-ready>
    <template #main-content>
      <fw-panel title="Explorar" featured>
        <panel-public-explore-procedures />
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import PanelPublicExploreProcedures from '@/components/panels/PanelPublicExploreProcedures'

export default {
  components: {
    PanelPublicExploreProcedures,
  },
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
