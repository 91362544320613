<template>
  <div>
    <ContextualSearch
      placeholder="Pesquisar por código..."
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :start-value="searchInput"
      :multifilter="true"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <fw-panel :title="$t('results')" :counter="procedures.length" :counter-total="totalResults" class="my-5">
      <fw-content-loader v-if="loading" type="card" max-width="5xl" />
      <fw-panel-info v-if="!loading && !procedures.length" clean>
        {{ $t('noProcedures') }}
      </fw-panel-info>
      <div v-else-if="!loading && procedures.length" class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-3">
        <template v-for="procedure in procedures">
          <card-procedure :key="procedure.key" :procedure="procedure" @open="openProcedure(procedure)" />
        </template>
      </div>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script>
import CardProcedure from '@/components/cards/CardProcedure'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    BlockPagination,
    CardProcedure,
    ContextualSearch,
    FilterTag,
  },

  data() {
    return {
      filterByType: 'all',
      filters: [
        {
          key: 'public_status',
          label: this.$t('procedurePublicStatus.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'open',
              label: this.$t('procedurePublicStatus.open'),
            },
            {
              key: 'ongoing',
              label: this.$t('procedurePublicStatus.ongoing'),
            },
            {
              key: 'ended',
              label: this.$t('procedurePublicStatus.ended'),
            },
          ],
        },
        {
          key: 'type',
          label: this.$t('procedureTypes.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'scholarships',
              label: this.$t('procedureTypes.scholarships'),
            },
          ],
        },
      ],

      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 24,
      procedures: [],
      loading: true,
      searchInput: '',
      appliedFilters: [],
      usersList: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    utils.sleep(100).then(async () => {
      this.getUrlParams()
      await this.getProcedures()
    })
  },

  methods: {
    async getProcedures() {
      this.loading = true

      let query = { limit: this.limit }

      // Deal with filters
      for (let filter of this.appliedFilters) {
        let filterCode = filter.split(':')[0]
        let filterValue = filter.split(':')[1]
        if (filterCode === 'public_status' && filterValue !== 'reset') {
          query['public_status'] = filterValue
        } else if (filterCode === 'type') {
          query['type'] = filterValue
        }
      }
      if (this.page > 1) {
        query['page'] = this.page
      }
      if (this.searchInput) {
        query['code'] = this.searchInput
      }

      utils.tryAndCatch(
        this,
        async () => {
          const result = await this.api.getProcedures(query)
          this.totalResults = result.pagination?.total_items
          this.totalPages = result.pagination?.total_pages
          this.page = result.pagination?.current_page
          this.procedures = result.procedures
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 750)
        }
      )
    },
    openProcedure(procedure) {
      this.$router.push(`/procedure/${procedure.code}`)
    },
    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term
      this.setUrlParams()

      // Reset page
      this.page = 1

      this.getProcedures()
    },
    getFilterText(key) {
      let split = key.split(':')
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (let j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getProcedures()
    },
    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }
      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }
      if (this.$route.query.p) {
        this.page = this.$route.query.p
      }
    },
    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }
      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }
      if (this.page > 1) {
        query['p'] = parseInt(this.page)
      }
      this.$router.push({ query: query })
    },
    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getProcedures()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "openApplicationsDate": "Data de abertura das candidaturas",
    "results": "Resultados",
    "noProcedures": "Sem procedimentos para apresentar.",
    "loadMoreData": "Ver mais resultados",
    "selectUser": "Selecione um utilizador",
    "user": "utilizador",
    "selectUserToFilter": "Selecione um utilizador para filtrar as atividades",
    "procedureStatus": {
      "label": "Estado(s) de procedimento(s)",
      "draft": "Rascunho",
      "jury": "Júri",
      "running": "Iniciado",
      "ended": "Audiência(s) de interessados",
      "closed": "Encerrado",
      "published": "Publicado"
    },
    "procedurePublicStatus": {
      "open": "Candidaturas abertas",
      "closed": "Candidaturas encerradas",
      "ongoing": "Em análise",
      "ended": "Terminado",
      "label": "Estado(s) de procedimento(s)"
    },
    "procedureTypes": {
      "label": "Tipo(s) de procedimento(s)",
      "teachers": "Professores",
      "researchers": "Investigadores",
      "researchers-dl57": "Investigadores DL 57",
      "scholarships": "Bolsas",
      "staff": "Pessoal não docente"
    },
    "all": "Todos",
    "orderBy": {
      "procedureCode": "Código do procedimento",
      "procedureStatus": "Estado do procedimento",
      "createdDate": "Data de criação",
      "publishStart": "Data de abertura das candidaturas",
      "publishEnd": "Data de fecho das candidaturas"
    }
  },
  "en": {
    "openApplicationsDate": "Opening date for applications",
    "results": "Results",
    "noProcedures": "No procedures to present.",
    "loadMoreData": "Load more data",
    "selectUser": "Select a user",
    "user": "User",
    "selectUserToFilter": "Select a user to filter activities",
    "procedureStatus": {
      "label": "Procedure status",
      "draft": "Draft",
      "jury": "Jury",
      "running": "Running",
      "ended": "Stakeholder Hearing",
      "closed": "Ended",
      "published": "Published"
    },
    "procedurePublicStatus": {
      "open": "Open to apply",
      "closed": "Closed to applications",
      "ongoing": "In analysis",
      "ended": "Ended",
      "label": "Procedure status"
    },
    "procedureTypes": {
      "label": "Procedure type",
      "teachers": "Teachers",
      "researchers": "Researchers",
      "researchers-dl57": "Researchers DL 57",
      "scholarships": "Scholarships",
      "staff": "Staff"
    },
    "all": "All",
    "orderBy": {
      "procedureCode": "Procedure code",
      "procedureStatus": "Procedure status",
      "createdDate": "Creation date",
      "publishStart": "Opening date for applications",
      "publishEnd": "Closing date for applications"
    }
  }
}
</i18n>
